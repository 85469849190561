import React from 'react';
import {makeStyles} from '@mui/styles';
import MainImg from '../../assets/images/front-post.png';
import Navbar from '../Header/Navbar';
import FeaturedProperties from './HomePage/FeaturedProperties';
import ChooseUs from './HomePage/ChooseUs';
import DevelopmentWorks from './HomePage/DevelopmentWorks';
import Gallery from './HomePage/Gallery';
import Footer from '../Footer';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PopularLocalities from './HomePage/PopularLocalities';


const HomePage = () => {
    const settings = {
        dots: true,
        arrows:true,
        autoplay: true,
        autoplaySpeed: 2500,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 775,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const classes = customStyles();
    return (
        <div>
            <Navbar />
            <div>
                {/* <div style={{backgroundColor: '#FFE5CC', margin: '1.5rem', padding: '2rem', color:'#EB252F'}}>
                    <Typography variant='h4' className={classes.cardTitle}>
                        Welcome to VIP Properties
                    </Typography>
                </div> */}
                <img src={MainImg} style={{width: '100%'}} alt='main image'/>
            </div>
            <PopularLocalities />
            <FeaturedProperties />
            <ChooseUs />
            <DevelopmentWorks />
            <Gallery />
            <Footer />
        </div>
    );
}

export default HomePage

const customStyles = makeStyles({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    cardTitle: {
        // color: '#808080',
        margin: '20px 20px',
        textAlign: 'center',
        fontWeight: 400,
        padding: 20
    },
    profileCardStyle: {
        boxShadow: 'none',
    },
    margin8a: {
        margin: '10px',
    },
    media: {
        height: 250,
    },
    text: {
        fontSize: '18px',
        margin: 2,
        fontWeight: 400
    },
    enquiryHome: {
        flexGrow: 1,
        padding: '80px 0',
    },
    gridSize: {
        display: 'flex',
        justifyContent: 'center',
        // width: '49vw',
    },
    typo: {
        padding: '15px 15px',
        margin: '1rem',
        color: '#000000',
        backgroundColor: 'inherit',
        borderRadius: 10,
        boxShadow: 'none',
        // textAlign: 'center'
    },
    typeAlignCenter: {
        textAlign: 'center',
    },
    padding16: {
        padding: '15px 0',
    },
    primaryTypo: {
        padding: '2px 10px',
        fontWeight: 600
    },
    viewProfileBtn: {
        minWidth: 120,
        padding: "8px 20px",
        margin: "0 0 25px 5px",
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor: "#00a03a",
        color: "#f7f7f7",
        border: "1px solid #00a03a",
        fontWeight: 600,

        '&:hover': {
            backgroundColor: "#999",
            color: "#000000",
            border: "1px solid #FFF7E7",
            boxShadow: "none",
        },
    },
})
